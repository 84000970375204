<template>
	<v-sheet fluid class="contacts">
		<transition name="fade-in-up">
			<router-view />
		</transition>
	</v-sheet>
</template>

<script>
export default {
	components: {},
};
</script>
